import latinize from 'latinize'

export default {
  methods: {
    customFilter (value, search, item) {
      value = String(value)
      let text = latinize(value.toLowerCase())
      return text.includes(search.toLowerCase())
    },
    autocompleteCustomFilter (item, search, value) {
      value = String(value)
      search = latinize(search)
      let text = latinize(value.toLowerCase())
      return text.includes(search.toLowerCase())
    }
  }
}
