<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.roles.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        class="xs"
        cols="12"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.roles.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.roles.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex flex-row-reverse align-center flex-grow-0 flex-shrink-0">
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon dark left>
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-card-title>
            <v-text-field
              :label="$t('app.buttons.search')"
              rounded
              outlined
              dense
              append-icon="mdi-magnify"
              v-model="filters.selected.query"
              clearable
            />
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="10"
            :search="queryClean"
            :custom-filter="customFilter"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                icon
                @click="openItemEdit(item)"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                icon
                @click="openItemDelete(item)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <roles-add />
    <roles-edit />
    <roles-delete />
  </v-container>
</template>

<script>
  import {mapMutations} from 'vuex'
  import latinize from 'latinize'
  import customFilter from "../../../../plugins/customFilter";

  export default {
    name: 'Servicios',
    mixins: [customFilter],
    components: {
      RolesAdd: () => import('./RolesAdd'),
      RolesEdit: () => import('./RolesEdit'),
      RolesDelete: () => import('./RolesDelete')
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: this.$t('app.headers.name'),
            value: 'name',
          },
          {
            text: this.$t('app.headers.description'),
            value: 'description',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        items: [],
        filters: {
          selected: {
            query: ''
          }
        },
        search: '',
      }
    },
    computed: {
      queryClean () {
        return latinize(this.filters.selected.query)
      }
    },
    async mounted () {
      await this.init()
      EventBus.$on('reload-items', async () => {
        await this.init()
      })
    },
    methods: {
      ...mapMutations({
        setRoles: 'SET_ROLES'
      }),
      async init () {
        this.toggleLoader()
        await this.getItems()
        this.toggleLoader()
      },
      async getItems () {
        await this.$http.get(route('v1/role'))
          .then(response => {
            if (response.body.code === 200) {
              this.items = response.body.data
              this.setRoles(this.items)
            } else {
              this.processError(response)
            }
          }, error => {
            this.processError(error)
          })
      },
      openItemAdd () {
        EventBus.$emit('roles-add')
      },
      openItemEdit (item) {
        EventBus.$emit('roles-edit', item)
      },
      openItemDelete (item) {
        EventBus.$emit('roles-delete', item)
      }
    },
  }
</script>
